import { defineStore } from 'pinia'
import { store } from '@admin/store'
import { fetchShopConfig } from '@admin/api/cos/site'

export const useSiteStore = defineStore('app-site', () => {
  // 全部站点
  const sites = ref<any>([])

  // 过滤站点
  const filterSites = computed(() => {
    return ({ status, channel = [] }: any = {}) => {
      return sites.value
        .filter((site) => (status === undefined ? true : site.status === status))
        .filter((site) => (channel.length === 0 ? true : channel.includes(site.channel)))
    }
  })

  const getSites = async () => {
    const { data } = await fetchShopConfig()
    sites.value = data
  }

  const getSiteByCode = (siteCode) => sites.value.find((site) => site.value === siteCode)

  return { sites, filterSites, getSites, getSiteByCode }
})

// Need to be used outside the setup
export function useSiteStoreWithOut() {
  return useSiteStore(store)
}
