<template>
  <a-radio-group
    class="!mb-[8px]"
    :value="internalValue"
    @update:value="handleChange"
    button-style="solid"
  >
    <a-radio-button v-for="site in options" :value="site.value" :key="site.value">
      {{ site.label }}
    </a-radio-button>
  </a-radio-group>

  <div v-for="site in options" v-show="internalValue === site.value" :key="site.value">
    <slot
      v-if="renderedSites.has(site.value)"
      :site-code="site.value"
      :site="site"
      :active-value="internalValue"
    ></slot>
  </div>
</template>
<script setup name="AppSiteTabs">
  import { useSiteStore } from '@admin/store/modules/site'

  const props = defineProps({
    actived: {
      // 是否激活的店铺
      type: Boolean,
      default: true,
    },
    channel: {
      type: [String, Array],
      default: () => ['Shopify'],
    },
    destroyInactiveSite: {
      // 是否销毁不活跃的站点tab
      type: Boolean,
      default: false,
    },
    value: String,
    defaultValue: String,
  })
  const emits = defineEmits(['update:value', 'change'])

  const siteStore = useSiteStore()
  const options = computed(() => {
    const filter = { channel: props.channel === 'all' ? [] : props.channel }
    if (props.actived !== 'all') filter.status = props.actived ? 1 : 0
    return siteStore.filterSites(filter)
  })

  const internalValue = ref(props.value || props.defaultValue || options.value[0]?.value)
  watch(
    () => props.value,
    (newVal) => {
      if (newVal === internalValue.value) return
      internalValue.value = newVal
    },
  )
  const handleChange = (val) => {
    internalValue.value = val
    emits('update:value', val)
    emits('change', val)
  }
  provide('activeSiteCode', internalValue)

  // 已渲染的site
  const renderedSites = ref(new Set())
  watch(
    () => [internalValue.value, props.destroyInactiveSite],
    () => {
      if (props.destroyInactiveSite) renderedSites.value.clear()
      renderedSites.value.add(internalValue.value)
    },
    { immediate: true },
  )
</script>
