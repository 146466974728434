import { LAYOUT } from '@admin/router/constant'

// 生产供应链系统
const supplyRouter = {
  path: '/srmSystem',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/operate/stockup-decision',
      name: 'stockDecision',
      component: () => import('@admin/views/cos/goodsManagement/stockDecision/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/docx/K3tjdKk5donTv5xykZ8ck5WrnEg',
        title: '备货决策',
      },
    },
    {
      path: '/operate/stockup-list',
      name: 'stockUpList',
      component: () => import('@admin/views/scm/stock/stockUp/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/docx/SrjndzbEuoTUT0xvO2tc26k3nDd',
        title: '备货单',
      },
    },
    {
      path: '/operate/check-stockup-list',
      name: 'checkStockUpList',
      component: () => import('@admin/views/cos/goodsManagement/checkStockUpList/index.vue'),
      meta: {
        title: '备货单确认',
      },
    },
    {
      path: '/operate/stock-rule-config',
      name: 'stockRuleConfig',
      component: () => import('@admin/views/scm/stock/stockRuleConfig/index.vue'),
      meta: {
        title: '备货规则配置',
      },
    },
    {
      path: '/operate/stocklist-edit',
      name: 'stockupListEdit',
      component: () => import('@admin/views/cos/goodsManagement/stockupList/edit.vue'),
      meta: { helpUrl: '', title: '备货单编辑' },
    },
    {
      path: '/operate/stocklist-look',
      name: 'stockupListLook',
      component: () => import('@admin/views/cos/goodsManagement/stockupList/look.vue'),
      meta: { helpUrl: '', title: '备货单查看' },
    },
    {
      path: '/operate/stocklist-add',
      name: 'stockupListAdd',
      component: () => import('@admin/views/cos/goodsManagement/stockupList/add.vue'),
      meta: { helpUrl: '', title: '备货单新建' },
    },
    {
      path: '/purchasePlan/purchaseOrder',
      name: 'purchaseOrder',
      component: () => import('@admin/views/scm/supplyChain/purchasePlan/purchaseOrder/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/CZnMwkNIeiA8cAkEDYJcV5ljnzh',
        title: '采购排单',
      },
    },
    {
      path: '/purchasePlan/purchasePlanList',
      name: 'purchasePlanList',
      component: () => import('@admin/views/scm/supplyChain/purchasePlan/index/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/CZnMwkNIeiA8cAkEDYJcV5ljnzh',
        title: '采购计划',
      },
    },
    {
      path: '/purchasePlan/merchandisingPlan',
      name: 'merchandisingPlan',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePlan/merchandisingPlan/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/CZnMwkNIeiA8cAkEDYJcV5ljnzh',
        title: '采购计划跟单',
      },
    },
    {
      path: '/purchasePlan/purchasePlanDetail',
      name: '',
      component: () => import('@admin/views/scm/supplyChain/purchasePlan/index/edit.vue'),
      meta: { helpUrl: '', title: '采购计划详情' },
    },
    {
      path: '/purchasePlan/merchandisingPlan/edit',
      name: 'merchandisingPlanEdit',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePlan/merchandisingPlan/edit.vue'),
      meta: { helpUrl: '', title: '新增采购计划跟单' },
    },
    {
      path: '/purchasePlan/merchandisingPlan/look',
      name: 'merchandisingPlanLook',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePlan/merchandisingPlan/look.vue'),
      meta: { helpUrl: '', title: '采购计划跟单详情' },
    },
    {
      path: '/purchaseOrder/schedule-monitoring',
      name: 'scheduleMonitoring',
      component: () =>
        import('@admin/views/scm/supplyChain/purchaseOrder/scheduleMonitoring/index.vue'),
      meta: { helpUrl: '', title: '采购进度监控' },
    },
    {
      path: '/purchaseOrder/list-new',
      name: 'purchaseOrderListNew',
      component: () => import('@admin/views/scm/supplyChain/purchaseOrder/indexNew/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/BzqFweqbViNt8XkKRcncg37cntF',
        title: '采购单',
      },
    },
    {
      path: '/purchaseOrder/list',
      name: 'purchaseOrderList',
      component: () => import('@admin/views/scm/supplyChain/purchaseOrder/index/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/CZnMwkNIeiA8cAkEDYJcV5ljnzh',
        title: '采购单(马帮)',
      },
    },
    {
      path: '/purchaseOrder/productionDocumentary',
      name: 'productionDocumentary',
      component: () =>
        import('@admin/views/scm/supplyChain/purchaseOrder/productionDocumentary/index.vue'),
      meta: { helpUrl: '', title: '采购跟单' },
    },
    {
      path: '/purchaseOrder/edit-new',
      name: 'purchaseOrderListDetailNew',
      component: () => import('@admin/views/scm/supplyChain/purchaseOrder/indexNew/edit.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/BzqFweqbViNt8XkKRcncg37cntF',
        title: '采购单详情',
      },
    },
    {
      path: '/purchaseOrder/detail',
      name: 'purchaseOrderListDetail',
      component: () => import('@admin/views/scm/supplyChain/purchaseOrder/index/edit.vue'),
      meta: { helpUrl: '', title: '采购单详情' },
    },
    {
      path: '/purchaseOrder/productionDocumentary/edit',
      name: 'productionDocumentaryEdit',
      component: () =>
        import('@admin/views/scm/supplyChain/purchaseOrder/productionDocumentary/edit.vue'),
      meta: { helpUrl: '', title: '新增采购跟单' },
    },
    {
      path: '/purchaseOrder/productionDocumentary/look',
      name: 'productionDocumentaryLook',
      component: () =>
        import('@admin/views/scm/supplyChain/purchaseOrder/productionDocumentary/look.vue'),
      meta: { helpUrl: '', title: '采购跟单详情' },
    },
    {
      path: '/purchasePreview/washingMark',
      name: '',
      component: () => import('@admin/views/scm/supplyChain/purchasePreview/washingMark/index.vue'),
      meta: { helpUrl: '', title: '洗水唛' },
    },
    {
      path: '/purchasePreview/orderContract',
      name: '',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePreview/orderContract/index.vue'),
      meta: { helpUrl: '', title: '采购合同' },
    },
    {
      path: '/purchasePreview/productionArchive',
      name: '',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePreview/productionArchive/index.vue'),
      meta: { helpUrl: '', title: '采购生产资料' },
    },
    {
      path: '/purchasePreview/returnPackingOrder',
      name: 'returnPackingOrder',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePreview/returnPackingOrder/index.vue'),
      meta: { helpUrl: '', title: '退货装箱单' },
    },
    {
      path: '/purchasePreview/returnOrder',
      name: '',
      component: () => import('@admin/views/scm/supplyChain/purchasePreview/returnOrder/index.vue'),
      meta: { helpUrl: '', title: '退货单' },
    },
    {
      path: '/purchasePreview/deliveryOrder',
      name: '',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePreview/deliveryOrder/index.vue'),
      meta: { helpUrl: '', title: '发货单' },
    },
    {
      path: '/purchasePreview/meansOfProduction',
      name: '',
      component: () =>
        import('@admin/views/scm/supplyChain/purchasePreview/meansOfProduction/index.vue'),
      meta: { helpUrl: '', title: '生产资料' },
    },
    {
      path: '/accountSettlement/payment',
      name: 'payMent',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/payment/index.vue'),
      meta: { helpUrl: '', title: '付款单' },
    },
    {
      path: '/accountSettlement/deduction',
      name: 'deduction',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/deduction/index.vue'),
      meta: { helpUrl: '', title: '补扣款' },
    },
    {
      path: '/accountSettlement/payment-add',
      name: '',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/payment/add.vue'),
      meta: { helpUrl: '', title: '新建付款单' },
    },
    {
      path: '/accountSettlement/payment-detail',
      name: 'paymentDetail',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/payment/detail.vue'),
      meta: { helpUrl: '', title: '付款单详情' },
    },
    {
      path: '/accountSettlement/payment-edit',
      name: 'editPayment',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/payment/edit.vue'),
      meta: { helpUrl: '', title: '付款单编辑' },
    },
    {
      path: '/accountSettlement/deduction-edit',
      name: 'editDeduction',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/deduction/edit.vue'),
      meta: { helpUrl: '', title: '补扣款单编辑' },
    },
    {
      path: '/accountSettlement/deduction-add',
      name: 'AddDeduction',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/deduction/add.vue'),
      meta: { helpUrl: '', title: '新增补扣款' },
    },
    {
      path: '/accountSettlement/deduction-detail',
      name: 'deductionDetail',
      component: () =>
        import('@admin/views/scm/supplyChain/accountSettlement/deduction/detail.vue'),
      meta: { helpUrl: '', title: '补扣款详情' },
    },
    {
      path: '/accountSettlement/statement',
      name: 'accountStatement',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/statement/index.vue'),
      meta: { helpUrl: '', title: '对账单' },
    },
    {
      path: '/accountSettlement/statement-edit',
      name: 'accountStatementEdit',
      component: () => import('@admin/views/scm/supplyChain/accountSettlement/statement/edit.vue'),
      meta: { helpUrl: '', title: '对账单编辑' },
    },
    {
      path: '/snapshot/production-data',
      name: 'productionData',
      component: () => import('@admin/views/scm/supplyChain/snapshot/productionData/index.vue'),
      meta: { helpUrl: '', title: '生产资料快照' },
    },
    {
      path: '/snapshot/wash-mark',
      name: 'washMark',
      component: () => import('@admin/views/scm/supplyChain/snapshot/washMark/index.vue'),
      meta: { helpUrl: '', title: '洗水唛快照' },
    },
    {
      path: '/snapshot/production-data-detail',
      name: 'productionDataDetail',
      component: () => import('@admin/views/scm/supplyChain/snapshot/productionData/detail.vue'),
      meta: { helpUrl: '', title: '生产资料快照详情' },
    },
    {
      path: '/snapshot/wash-mark-detail',
      name: 'washMarkDetail',
      component: () => import('@admin/views/scm/supplyChain/snapshot/washMark/detail.vue'),
      meta: { helpUrl: '', title: '查看洗水唛详情' },
    },
    {
      path: '/snapshot/pattern-file',
      name: 'patternFile',
      component: () => import('@admin/views/scm/supplyChain/snapshot/patternFile/index.vue'),
      meta: { helpUrl: '', title: '纸样文件快照' },
    },
    {
      path: '/sign/sign-desk',
      name: 'signDesk',
      component: () => import('@admin/views/scm/receivingManager/sign/signDesk/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/CpLbw56NLiW2PhknKywcnj2envh',
        title: '签收台',
      },
    },
    {
      path: '/sign/sign-form',
      name: 'signForm',
      component: () => import('@admin/views/scm/receivingManager/sign/signForm/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/CpLbw56NLiW2PhknKywcnj2envh',
        title: '签收单',
      },
    },
    {
      path: '/take/take-desks',
      name: 'takeDesk',
      component: () => import('@admin/views/scm/receivingManager/take/takeDesk/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/TFoZwFVxiitXOxkj6LlcJfsqnae',
        title: '收货台',
      },
    },
    {
      path: '/take/take-form',
      name: 'takeForm',
      component: () => import('@admin/views/scm/receivingManager/take/takeForm/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/TFoZwFVxiitXOxkj6LlcJfsqnae',
        title: '收货单',
      },
    },
    {
      path: '/scale/desk',
      name: 'scaleDesk',
      component: () => import('@admin/views/scm/inspectManager/scale/scaleDesk/edit.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/EHUewBL5PiKzd2kS7vecTjcJndd',
        title: '度尺台',
      },
    },
    {
      path: '/scale/deskLook',
      name: 'scaleDeskLook',
      component: () => import('@admin/views/scm/inspectManager/scale/scaleDesk/look.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/EHUewBL5PiKzd2kS7vecTjcJndd',
        title: '度尺台查看',
      },
    },
    {
      path: '/scale/form',
      name: 'scaleForm',
      component: () => import('@admin/views/scm/inspectManager/scale/scaleForm/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/EHUewBL5PiKzd2kS7vecTjcJndd',
        title: '度尺单',
      },
    },
    {
      path: '/inspect/inspect-desk',
      name: 'inspectDesk',
      component: () => import('@admin/views/scm/inspectManager/inspect/inspectDesk/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/IKFrww7Mji3x1gk9COYcT4oLnGe',
        title: '质检台',
      },
    },
    {
      path: '/inspect/inspect-form',
      name: 'inspectForm',
      component: () => import('@admin/views/scm/inspectManager/inspect/inspectForm/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/IKFrww7Mji3x1gk9COYcT4oLnGe',
        title: '质检单',
      },
    },
    {
      path: '/warehouseManager/warehouse-warrant',
      name: 'warehouseWarrant',
      component: () =>
        import('@admin/views/scm/receivingManager/warehouse/warehouseWarrant/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/UAiewydNSinhT2k4N95cFS9AnPb',
        title: '入库单',
      },
    },
    {
      path: '/warehouseManager/warehouse-log',
      name: 'warehouseLog',
      component: () => import('@admin/views/scm/receivingManager/warehouse/warehouseLog/index.vue'),
      meta: { helpUrl: '', title: '入库日志' },
    },
    {
      path: '/return/return-report',
      name: 'returnReport',
      component: () => import('@admin/views/scm/returnManagement/returnReport/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/Cjb4w8isji4k1PkRKKicsoWanDe',
        title: '退货单',
      },
    },
    {
      path: '/return/return-packing',
      name: 'returnPacking',
      component: () => import('@admin/views/scm/returnManagement/returnPacking/index.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/Cjb4w8isji4k1PkRKKicsoWanDe',
        title: '退货装箱单',
      },
    },
    {
      path: '/return/return-report-edit',
      name: 'returnReportEdit',
      component: () => import('@admin/views/scm/returnManagement/returnReport/edit.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/Cjb4w8isji4k1PkRKKicsoWanDe',
        title: '退货单详情',
      },
    },
    {
      path: '/return/add-return-packing',
      name: 'returnPackingEdit',
      component: () => import('@admin/views/scm/returnManagement/returnPacking/add.vue'),
      meta: {
        helpUrl: 'https://bloomchic.feishu.cn/wiki/Cjb4w8isji4k1PkRKKicsoWanDe',
        title: '新建退货装箱单',
      },
    },
    {
      path: '/basicData/material',
      name: 'baseMaterialer',
      component: () => import('@admin/views/scm/supplyChain/basicData/material/index.vue'),
      meta: { helpUrl: '', title: '面辅料供应商' },
    },
    {
      path: '/basicData/material/edit',
      name: 'basicDataMaterialDetail',
      component: () => import('@admin/views/scm/supplyChain/basicData/material/edit.vue'),
      meta: { helpUrl: '', title: '面辅料供应商详情' },
    },
    {
      path: '/basicData/production',
      name: 'baseProduction',
      component: () => import('@admin/views/scm/supplyChain/basicData/production/index.vue'),
      meta: { helpUrl: '', title: '生产供应商' },
    },
    {
      path: '/basicData/production/edit',
      name: 'basicDataProductionDetail',
      component: () => import('@admin/views/scm/supplyChain/basicData/production/edit.vue'),
      meta: { helpUrl: '', title: '生产供应商详情' },
    },
    {
      path: '/basicData/processing',
      name: 'baseProcessing',
      component: () => import('@admin/views/scm/supplyChain/basicData/processing/index.vue'),
      meta: { helpUrl: '', title: '二次供应商' },
    },
    {
      path: '/basicData/processing/edit',
      name: 'basicDataProcessingDetail',
      component: () => import('@admin/views/scm/supplyChain/basicData/processing/edit.vue'),
      meta: { helpUrl: '', title: '二次供应商详情' },
    },
    {
      path: '/basicData/purchasingAnomaly',
      name: 'purchasingAnomaly',
      component: () => import('@admin/views/scm/supplyChain/basicData/purchasingAnomaly/index.vue'),
      meta: { helpUrl: '', title: '跟单异常分类' },
    },
    {
      path: '/basicData/supplyAnomaly',
      name: 'supplyAnomaly',
      component: () => import('@admin/views/scm/supplyChain/basicData/supplyAnomaly/index.vue'),
      meta: { helpUrl: '', title: '供应异常分类' },
    },
    {
      path: '/basicData/schedulingRule',
      name: 'schedulingRule',
      component: () => import('@admin/views/scm/supplyChain/basicData/schedulingRule/index.vue'),
      meta: { helpUrl: '', title: '排单规则' },
    },
    {
      path: '/workorder/workCenter',
      name: 'workCenter',
      component: () => import('@admin/views/scm/workManagement/workCenter/index.vue'),
      meta: { helpUrl: '', title: '工单中心' },
    },
    {
      path: '/workorder/allWorkCenter',
      name: 'allWorkCenter',
      component: () => import('@admin/views/scm/workManagement/allWorkCenter/index.vue'),
      meta: { helpUrl: '', title: '全部工单' },
    },
    {
      path: '/approve/approve-center',
      name: 'approveCenter',
      component: () => import('@admin/views/scm/approveManagement/approveCenter/index.vue'),
      meta: { helpUrl: '', title: '审批中心' },
    },
    {
      path: '/workorder/workCenter-edit',
      name: 'workCenterEdit',
      component: () => import('@admin/views/scm/workManagement/workCenter/edit.vue'),
      meta: { helpUrl: '', title: '工单详情编辑' },
    },
    {
      path: '/workorder/abnormal',
      name: 'createAbnormal',
      component: () => import('@admin/views/scm/workManagement/abnormal/index.vue'),
      meta: { helpUrl: '', title: '创建异常工单' },
    },
    {
      path: '/approve/approve-center-edit',
      name: 'approveCenterEdit',
      component: () => import('@admin/views/scm/approveManagement/approveCenter/edit.vue'),
      meta: { helpUrl: '', title: '审批单详情' },
    },
    {
      path: '/approve/approve-config-record',
      name: 'approveConfigRecord',
      component: () => import('@admin/views/scm/approveManagement/approveConfig/record.vue'),
      meta: { helpUrl: '', title: '审批申请记录' },
    },
    {
      path: '/approve/approve-config',
      name: 'approveConfig',
      component: () => import('@admin/views/scm/approveManagement/approveConfig/index.vue'),
      meta: { helpUrl: '', title: '审批流配置' },
    },
    {
      path: '/approve/approve-config-edit',
      name: 'approveConfigEdit',
      component: () => import('@admin/views/scm/approveManagement/approveConfig/edit.vue'),
      meta: { helpUrl: '', title: '编辑审批流' },
    },
    {
      path: '/report-center/warehouseLink',
      name: 'warehouseLinks',
      component: () => import('@admin/views/scm/supplyChain/reportCenter/warehouseLink/index.vue'),
      meta: { helpUrl: '', title: '仓库环节作业统计表' },
    },
    {
      path: '/crossingRate/crossingRateThree',
      name: 'crossingRateThree',
      component: () =>
        import('@admin/views/scm/supplyChain/reportCenter/crossingRate/crossingRateThree.vue'),
      meta: { helpUrl: '', title: '3.1准交率' },
    },
    {
      path: '/crossingRate/crossingRateFouth',
      name: 'crossingRateFouth',
      component: () =>
        import('@admin/views/scm/supplyChain/reportCenter/crossingRate/crossingRateFouth.vue'),
      meta: { helpUrl: '', title: '4.1准交率' },
    },
    {
      path: '/report-center/productionPerformance',
      name: 'productionPerformance',
      component: () =>
        import('@admin/views/scm/supplyChain/reportCenter/productionPerformance/index.vue'),
      meta: { helpUrl: '', title: '供应商生产绩效表' },
    },
    {
      path: '/report-center/commodityLife',
      name: 'commodityLife',
      component: () => import('@admin/views/scm/supplyChain/reportCenter/commodityLife/index.vue'),
      meta: { helpUrl: '', title: '商品生产周期' },
    },
    {
      path: '/report-center/supplier-grade-dashboard',
      name: 'supplierGradeDashboard',
      component: () =>
        import('@admin/views/scm/supplyChain/reportCenter/supplierGradeDashboard/index.vue'),
      meta: { helpUrl: '', title: '供应商等级考核看板' },
    },
    {
      path: '/workbench/workbench',
      name: 'workbench',
      component: () => import('@admin/views/system/workbench/index.vue'),
      meta: {
        title: '工作台',
        affix: true,
      },
    },
    {
      path: '/cmd/jumpPage',
      name: 'jumpPage',
      component: () => import('@admin/views/system/cmdTransferPage/index.vue'),
      meta: {
        title: ' ',
      },
    },
    {
      path: '/scm/base-data/production-process',
      name: 'productionProcess',
      component: () => import('@admin/views/scm/baseData/productionProcess/index.vue'),
      meta: {
        title: '生产工序',
      },
    },
    {
      path: '/scm/base-data/category-process-timelines',
      name: 'categoryProcessTimelines',
      component: () => import('@admin/views/scm/baseData/categoryProcessTimelines/index.vue'),
      meta: {
        title: '品类工序时效',
      },
    },
    {
      path: '/purchaseOrder/reporting-record',
      name: 'reportingRecord',
      component: () =>
        import('@admin/views/scm/supplyChain/purchaseOrder/reportingRecord/index.vue'),
      meta: {
        title: '生产上报记录',
      },
    },
    {
      path: '/purchaseOrder/daily-order-tracking-report',
      name: 'dailyOrderTrackingReport',
      component: () =>
        import('@admin/views/scm/supplyChain/purchaseOrder/dailyOrderTrackingReport/index.vue'),
      meta: {
        title: '每日跟单汇报',
      },
    },
  ],
}

export default supplyRouter
