import { LAYOUT } from '@admin/router/constant'

const basicRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@admin/views/system/login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/productTags',
    name: 'productTags',
    component: () => import('@admin/views/scm/supplyChain/purchaseOrder/productTags/index.vue'),
    meta: {
      title: '商品标签',
      hideBreadcrumb: true,
      hideMenu: true,
    },
  },
  {
    path: '/redirect',
    component: LAYOUT,
    name: 'RedirectTo',
    meta: {
      title: 'Redirect',
    },
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@admin/views/system/redirect/index.vue'),
        meta: {
          title: 'Redirect',
        },
      },
    ],
  },
  {
    path: '/iframeSystem',
    component: LAYOUT,
    name: 'IframeSystem',
    meta: {
      title: 'IframeSystem',
    },
    children: [
      {
        path: '/iframe',
        name: 'iframe',
        component: () => import('@admin/views/common/iframe/index.vue'),
        meta: {
          noCache: false,
          iframe: true,
          helpUrl: '',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: LAYOUT,
    meta: {
      title: 'ErrorPage',
    },
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: 'PAGE_NOT_FOUND_NAME',
        component: () => import('@admin/views/system/exception/Exception.vue'),
        meta: {
          title: 'ErrorPage',
          hideBreadcrumb: true,
          hideMenu: true,
        },
      },
    ],
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('@admin/views/common/demo/index.vue'),
    meta: {
      title: 'demo',
    },
  },
  {
    path: '/demo/export',
    name: 'demoExport',
    component: () => import('@admin/views/common/demo/export/index.vue'),
    meta: {
      title: 'demoExport',
    },
  },
]
export default basicRoutes
